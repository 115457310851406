@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  background: colors.$primary-color;
  margin-bottom: 1px;
  padding: 30px 0 45px 0;

  .header {
    align-items: center;
    font-size: 2rem;
    letter-spacing: 0.013rem;
    line-height: 2.375rem;
    color: colors.$white;
    display: flex;
    font-weight: 700;
    margin-bottom: 24px;

    span {
      font-weight: 300;
      margin-right: 8px;
    }

    @media screen and (min-width: vars.$medium) {
      font-size: 2.25rem;
      line-height: 3rem;
      letter-spacing: 0.025rem;
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: vars.$extra-large) {
    padding: 60px 0 60px 0;

    .header {
      line-height: initial;
    }
  }
}

.boxShadow {
  box-shadow: 0px;
  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.delete {
  background: transparent;
  border: none;
  cursor: pointer;
  color: colors.$white;
  font-weight: bold;
}

.deleteIcon {
  margin-right: 5px;
}

.slidersRoot {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

.recentlyViewedProductsIcon {
  font-size: 24px;
  margin-right: 15px;

  @media screen and (min-width: vars.$medium) {
    font-size: 30px;
  }
}

.sliderCard {
  flex: 0 0 auto;
  margin-right: 15px;
  width: 200px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: vars.$medium) {
    width: 224px;
  }

  @media screen and (min-width: vars.$large) {
    width: 298px;
  }

  @media screen and (min-width: vars.$extra-large) {
    width: 270px;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    width: 230px;
  }
}

.navBackground {
  background-color: colors.$light-white;
  color: colors.$primary-color;
  border: 2px solid colors.$primary-color;
}

.deleteListButton {
  margin-top: 16px;
}

.seeAll {
  border: 1px solid #fff;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;

  & a {
    color: #fff;
    text-decoration: none;
    width: 230px;
    padding: 24px 16px 16px 16px;

    & h3 {
      font-size: 20px;

      @media screen and (min-width: vars.$medium) {
        font-size: 28px;
      }
    }
  }
}
